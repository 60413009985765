import {
  ONBOARDING_FLOW_CONFIG,
  type EnabledOnboardingStep,
  type NullableOnboardingStep,
} from "@brm/type-helpers/onboarding.js"
import { FormattedMessage } from "react-intl"
import {
  AgreementIcon,
  CalendarCheckIcon,
  CheckIcon,
  DocumentIcon,
  EyeIcon,
  InboxIcon,
  RequestDetailsIcon,
  VendorIcon,
} from "../icons/icons.js"

interface StepCardConfig {
  icon: React.ElementType
  heading: React.ReactNode
  subtitle: React.ReactNode
  canSkip: boolean
}

export const OnboardingCalloutConfigurations: Partial<Record<EnabledOnboardingStep, StepCardConfig>> = {
  "overview.view_agreement": {
    icon: AgreementIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.view_agreement"
        description="Introduction to viewing a sample agreement in BRM"
        defaultMessage="Let’s see BRM in action"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.view_agreement.subtitle"
        description="Explanation of BRM’s automatic agreement import feature"
        defaultMessage="Now that you’ve connected your email, BRM will automatically import vendor contracts - what we call agreements. We’re going to use this sample agreement to show you how BRM works."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["overview.view_agreement"].canSkipToNextStep,
  },
  "verify_agreement.verify_vendor": {
    icon: VendorIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.verify_vendor"
        description="Introduction to BRM’s information extraction capabilities"
        defaultMessage="BRM automatically extracts important information from the agreement"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.verify_vendor.subtitle"
        description="Prompt for user to click the agreement source to see the extracted information source"
        defaultMessage="Click on the purple text to see where in the agreement BRM found the information."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["verify_agreement.verify_vendor"].canSkipToNextStep,
  },
  "verify_agreement.verify_auto_renews": {
    icon: DocumentIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.verify_auto_renews"
        description="Introduction to BRM’s multi-document handling"
        defaultMessage="Multiple documents? No problem"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.verify_auto_renews.subtitle"
        description="Explanation of BRM’s multi-document handling"
        defaultMessage="BRM will map multiple document types to a single agreement, including terms of service, DPAs, NDAs, and more. Click on the purple text to see the terms of service that include this legal term."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["verify_agreement.verify_auto_renews"].canSkipToNextStep,
  },
  "verify_agreement.verify_decision_date": {
    icon: CalendarCheckIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.verify_decision_date"
        description="Introduction to BRM’s renewal tracking feature"
        defaultMessage="Always accurate renewal decision date, even with complex legal terms"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.verify_decision_date.subtitle"
        description="Explanation of BRM’s renewal tracking feature"
        defaultMessage="BRM automatically calculates the renewal decision date based on a combination of legal terms and the end date of the agreement."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["verify_agreement.verify_decision_date"].canSkipToNextStep,
  },
  "verify_agreement.accept_agreement": {
    icon: CheckIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.accept_agreement"
        description="Prompt for user to accept the agreement"
        defaultMessage="Verify the agreement"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.accept_agreement.subtitle"
        description="Explanation of BRM’s agreement verification feature"
        defaultMessage="BRM makes it easy to see the source of the extracted data so you can confirm that it’s correct."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["verify_agreement.accept_agreement"].canSkipToNextStep,
  },
  "overview.view_renewal_agenda": {
    icon: AgreementIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.view_renewal_agenda"
        description="Introduction to the Renewal Agenda feature"
        defaultMessage="Welcome to your Renewal Agenda"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.view_renewal_agenda.subtitle"
        description="Message shown when user needs to view renewal agenda"
        defaultMessage="Track all your upcoming renewals in one place, prioritized by decision date."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["overview.view_renewal_agenda"].canSkipToNextStep,
  },
  "configure_notifications.renewal_reminder": {
    icon: CalendarCheckIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.renewal_reminder"
        description="Message shown when user needs to set renewal reminder"
        defaultMessage="Put renewals on autopilot with BRM reminder emails"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.renewal_reminder.subtitle"
        description="Message shown when user needs to set renewal reminder"
        defaultMessage="Tell BRM how far in advance you want to be notified about upcoming renewals."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["configure_notifications.renewal_reminder"].canSkipToNextStep,
  },
  "configure_notifications.connect_slack": {
    icon: RequestDetailsIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.connect_slack"
        description="Introduction to Slack integration feature"
        defaultMessage="Connect Slack to get notified where you work"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.connect_slack.subtitle"
        description="Message shown when user needs to set renewal reminder"
        defaultMessage="Connect Slack to get notified about upcoming renewals and documents that need verification."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["configure_notifications.connect_slack"].canSkipToNextStep,
  },
  "overview.view_inbox": {
    icon: InboxIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.view_inbox"
        description="Introduction to BRM’s inbox feature"
        defaultMessage="The Inbox shows you the items in BRM that need your attention"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.view_inbox.subtitle"
        description="Explanation of BRM’s task management system"
        defaultMessage="Whether it’s notifications about upcoming renewals or tasks to verify new agreements BRM found in your inbox, you will find it all here."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["overview.view_inbox"].canSkipToNextStep,
  },
  "inbox.verify_agreements": {
    icon: EyeIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.verify_agreements"
        description="Message shown when user needs to verify agreements"
        defaultMessage="You’re all set! Now it’s time to verify the agreements BRM found in your email inbox."
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.verify_agreements.subtitle"
        description="Message shown when user needs to verify agreements"
        defaultMessage="Click on each agreement to verify the key details that BRM extracted."
      />
    ),
    canSkip: ONBOARDING_FLOW_CONFIG["inbox.verify_agreements"].canSkipToNextStep,
  },
}

export const isCalloutConfiguredStep = (step: NullableOnboardingStep): boolean => {
  return step !== null && step in OnboardingCalloutConfigurations
}
