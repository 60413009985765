import { skipToken } from "@reduxjs/toolkit/query"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useGetNegotiationV1ByIdQuery } from "../../app/services/generated-api.js"
import Chat from "../Chat.js"

const Negotiation = () => {
  const navigate = useNavigate()
  const { vendorId, negotiationId } = useParams()
  const { data: negotiation } = useGetNegotiationV1ByIdQuery(negotiationId ? { id: negotiationId } : skipToken)

  useEffect(() => {
    if (!negotiationId) {
      navigate(`/negotiations/${vendorId}/${crypto.randomUUID()}`)
    }
  }, [negotiationId, vendorId, navigate])

  if (!vendorId || !negotiationId) {
    return null
  }

  // For now we will pull the latest conversation.
  // TODO: Add ability to view archived conversations
  const conversation = negotiation?.conversations.at(0)
  return (
    <Chat
      conversation={conversation ?? { id: crypto.randomUUID() }}
      onSubmitStreamingUrl={`${import.meta.env.VITE_API_BASE_URL}/negotiation/v1/compose`}
      addOns={{
        emailDraft: {
          enabled: true,
          emailThread: negotiation?.email_thread,
        },
      }}
      additionalRequestParams={{
        negotiation_id: negotiationId,
        vendor_id: vendorId,
      }}
    />
  )
}

export default Negotiation
