import type { EmailRichTextDraft } from "@brm/schema-types/types.js"
import { EMPTY_RICH_TEXT_BODY } from "../RichTextEditor/util/common.js"

export const EMPTY_EMAIL_DRAFT: EmailRichTextDraft = {
  body: EMPTY_RICH_TEXT_BODY,
  to: [],
  cc: [],
  bcc: [],
  subject: "",
  html_reply_body: null,
  include_reply_body: false,
}
