import { hasPermission } from "@brm/schema-helpers/role.js"
import type { DocumentMinimal, LegalAgreementListItem, LegalAgreementMinimal } from "@brm/schema-types/types.js"
import { WorkflowSoftwareRenewalKindSchema } from "@brm/schemas"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { pickFieldsFromSchema } from "@brm/util/schema.js"
import {
  Badge,
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  Icon,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { createColumnHelper, type ColumnPinningState } from "@tanstack/react-table"
import { useCallback, useEffect, useLayoutEffect, useMemo, useState, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { isNotUndefined } from "typed-assert"
import emptyStateGif from "../../../assets/legal_agreement_empty_state.gif"
import {
  useGetDocumentV1ExtractingQuery,
  useGetSavedViewV1ByTableIdentifierQuery,
  useGetUserV1WhoamiQuery,
  useGetWorkflowV1DefinitionsQuery,
  usePostDocumentV1BackgroundExtractBatchMutation,
  usePostLegalV1AgreementsListQuery,
} from "../../app/services/generated-api.js"
import DataTable from "../../components/DataTable/DataTable.js"
import FullDataTableHeader from "../../components/DataTable/FullDataTableHeader.js"
import TablePageHeader from "../../components/DataTable/SchemaFilter/TablePageHeader.js"
import SchemaTableColumnCustomization from "../../components/DataTable/SchemaTableColumnCustomization.js"
import { ACTIONS_COLUMN_SIZE } from "../../components/DataTable/const.js"
import type { SavedViewProps } from "../../components/DataTable/types.js"
import { useSchemaColumns } from "../../components/DataTable/use-schema-columns.js"
import {
  useLocalStorageTableParamsSync,
  useUrlTableParams,
} from "../../components/DataTable/use-schema-table-params.js"
import { DocumentsBulkUploadModal } from "../../components/Document/DocumentsUploadModal.js"
import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip.js"
import { Link } from "../../components/Link.js"
import { MoreMenuIcon, UploadIcon } from "../../components/icons/icons.js"
import Spinner from "../../components/spinner.js"
import { pathForLegalAgreement } from "../../util/json-schema.js"
import {
  TABLE_INITIAL_PAGE,
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
} from "../../util/schema-table.js"
import { getTabIndexFromLocation, type FilterTabData } from "../../util/tabs.js"
import { useObjectSchema } from "../../util/use-schema.js"
import { goToNextOnboardingStep, selectCurrentOnboardingStep } from "../onboarding/onboarding-slice.js"
import { AGREEMENT_VERIFICATION_SELECT_TOP_AGREEMENT_HASH } from "../workflows/constants.js"
import StartWorkflowModal from "../workflows/run/start/StartWorkflowModal.js"
import { DeleteLegalAgreementModal } from "./DeleteLegalAgreementModal.js"
import type { LegalAgreementOption } from "./LegalAgreementPicker.js"
import { LEGAL_AGREEMENTS_TABLE_DEFAULT_PARAMS, LEGAL_AGREEMENTS_TABLE_ID } from "./constants.js"
import { documentStatusText } from "./helpers.js"

const columnHelper = createColumnHelper<LegalAgreementListItem>()

const defaultColumns: BRMPaths<LegalAgreementListItem>[] = [
  "vendor",
  "verification_status",
  "source",
  "total_contract_value",
  "start_date",
  "end_date",
  "created_at",
  "document_count",
]
const liteColumns: BRMPaths<LegalAgreementListItem>[] = [
  "decision_date",
  "auto_renews",
  "auto_renewal_opt_out_period",
  "created_by",
]

const primaryColumn = "display_name" satisfies BRMPaths<LegalAgreementListItem>
const columnPinning: ColumnPinningState = {
  left: [primaryColumn],
}

const LegalAgreementList: FunctionComponent = () => {
  const intl = useIntl()
  const collator = useMemo(() => new Intl.Collator(intl.locale), [intl.locale])
  const toast = useToast()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentStep = useSelector(selectCurrentOnboardingStep)
  const isOnboardingVerifyAgreements = currentStep === "inbox.verify_agreements"
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])
  const bulkUploadOpen = hashParams.get("upload")

  const [backgroundExtractDocuments] = usePostDocumentV1BackgroundExtractBatchMutation()

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const isLiteOrganization = whoami?.organization?.is_lite
  const fullLegalAgreementSchema = useObjectSchema("LegalAgreement")
  const legalAgreementSchema = useMemo(() => {
    if (!fullLegalAgreementSchema) {
      return undefined
    }

    if (!isLiteOrganization) {
      return fullLegalAgreementSchema
    }
    // this joined list of available columns causes a complex type error so we have to cast to string[]
    return pickFieldsFromSchema(fullLegalAgreementSchema, [
      primaryColumn,
      ...(defaultColumns as string[]),
      ...(liteColumns as string[]),
    ])
  }, [fullLegalAgreementSchema, isLiteOrganization])

  const { data: savedViewData } = useGetSavedViewV1ByTableIdentifierQuery({
    tableIdentifier: LEGAL_AGREEMENTS_TABLE_ID,
  })

  useLocalStorageTableParamsSync(LEGAL_AGREEMENTS_TABLE_ID)
  const { tableParams, updateTableParams, getUpdatedSerializedParams } = useUrlTableParams<string>({
    defaultParams: LEGAL_AGREEMENTS_TABLE_DEFAULT_PARAMS,
    objectSchema: legalAgreementSchema,
    savedViews: savedViewData,
    primarySearchColumn: primaryColumn,
  })

  const pendingReviewTabIndex = 0
  const filterTabs = useMemo(
    () =>
      [
        {
          locationHash: "pending_review",
          label: (
            <FormattedMessage
              id="agreements.list.page.tab.pending_review"
              description="Tab label to show pending review  on the agreements list page"
              defaultMessage="Pending review"
            />
          ),
          filters: [
            [
              {
                column: "verification_status",
                fields: { comparator: "any", includeNull: false, values: ["in_review", "draft"] },
              },
            ],
          ],
        },
        {
          locationHash: "all",
          label: (
            <FormattedMessage
              id="agreements.list.page.tab.all"
              description="Tab label to show all agreements on the agreements list page"
              defaultMessage="All agreements"
            />
          ),
          filters: undefined,
        },
      ] satisfies FilterTabData[],
    []
  )

  const tabIndex = filterTabs && getTabIndexFromLocation(location, filterTabs)

  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )
  const schemaColumns = useSchemaColumns<LegalAgreementListItem>({
    objectSchema: legalAgreementSchema,
    shownColumns,
    primaryColumn,
  })

  const apiParams = useMemo(
    () =>
      tableParams &&
      legalAgreementSchema &&
      packageSortFilterOptionsForAPI<string>(tableParams, legalAgreementSchema, intl),
    [tableParams, legalAgreementSchema, intl]
  )

  // Fetch renewal workflow definition to determine if user can start a renewal from the table actions menu
  const { data: workflowDefinitions } = useGetWorkflowV1DefinitionsQuery()
  const missingRenewalDefinition = !workflowDefinitions?.some(
    (def) => def.kind === WorkflowSoftwareRenewalKindSchema.const
  )

  const allAgreementsResult = usePostLegalV1AgreementsListQuery(
    apiParams ? { listQueryStringParams: apiParams } : skipToken
  )

  const pendingReviewAgreementsResult = usePostLegalV1AgreementsListQuery(
    apiParams
      ? {
          listQueryStringParams: {
            ...apiParams,
            filter: [...apiParams.filter, ...(filterTabs[0]?.filters ?? [])],
          },
        }
      : skipToken
  )

  useLayoutEffect(() => {
    if (location && location.hash.includes(AGREEMENT_VERIFICATION_SELECT_TOP_AGREEMENT_HASH)) {
      // If coming from inbox, the task type will be included in the pathname after /tasks. Keep the taskType to include back in the pathname on redirect
      const inboxPathMatch = location.pathname.match(/\/inbox\/tasks\/([^/]+)/u)
      const firstAgreement = pendingReviewAgreementsResult.data?.items[0]
      if (!firstAgreement) {
        return
      }

      navigate(
        `${inboxPathMatch ? `/inbox/tasks/${inboxPathMatch[1]}` : ""}${pathForLegalAgreement(firstAgreement.id, true)}`,
        { state: {} }
      )
    }
  }, [location, pendingReviewAgreementsResult, navigate])

  const tabResult = [pendingReviewAgreementsResult, allAgreementsResult]

  const handleChangeTab = useCallback(
    (index: number) => {
      const newTab = filterTabs[index]
      isNotUndefined(newTab)
      if (newTab.locationHash) {
        hashParams.set("tab", newTab.locationHash)
      } else {
        hashParams.delete("tab")
      }
      navigate(
        {
          search: getUpdatedSerializedParams?.({ page: TABLE_INITIAL_PAGE }).toString(),
          hash: `#${hashParams}`,
        },
        { replace: true }
      )
    },
    [filterTabs, getUpdatedSerializedParams, hashParams, navigate]
  )

  useEffect(() => {
    if (pendingReviewAgreementsResult.data?.total === 0 && tabIndex !== 1) {
      handleChangeTab(1)
    }
  }, [handleChangeTab, pendingReviewAgreementsResult.data?.total, tabIndex])

  // Progress tracking for extraction
  const extractingDocuments = useGetDocumentV1ExtractingQuery(undefined)

  const deleteAgreementModal = useDisclosure()
  const [agreementBeingDeleted, setAgreementBeingDeleted] = useState<LegalAgreementMinimal | null>(null)

  const startRenewalModal = useDisclosure()
  const [legalAgreementBeingRenewed, setLegalAgreementBeingRenewed] = useState<LegalAgreementOption | null>(null)

  const bulkUploadModal = useDisclosure({
    isOpen: !!bulkUploadOpen,
    onClose: () => {
      hashParams.delete("upload")
      navigate({ search: location.search, hash: `#${hashParams}` })
    },
    onOpen: () => {
      hashParams.set("upload", "true")
      navigate({ search: location.search, hash: `#${hashParams}` })
    },
  })

  // Note: Avoiding rerenders is important to not close menus on each poll
  const { onOpen: openDeleteAgreementModal } = deleteAgreementModal
  const { onOpen: openStartRenewalModal } = startRenewalModal

  const columnsWithActions = useMemo(
    () =>
      schemaColumns && [
        ...schemaColumns,
        columnHelper.display({
          id: "actions-column",
          size: ACTIONS_COLUMN_SIZE,
          meta: {
            textAlign: "center",
          },
          header: intl.formatMessage({
            id: "legalAgreements.table.header.actions",
            description: "Invitation table column header for Actions",
            defaultMessage: "Actions",
          }),
          cell: ({ row: { original: agreement } }) => {
            return (
              <Menu variant="tableCell" isLazy>
                <MenuButton
                  as={IconButton}
                  variant="ghost"
                  icon={<Icon as={MoreMenuIcon} />}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Actions",
                    description: "The ARIA label for the actions dropdown button in the legal agreements table",
                    id: "legalAgreements.table.action.actions.label",
                  })}
                />
                <MenuList>
                  <MenuItem
                    as={Link}
                    to={pathForLegalAgreement(agreement.id)}
                    state={{ prevLocation: location }}
                    isDisabled={!hasPermission(whoami?.roles, "legal:update")}
                    onClick={() => {
                      if (isOnboardingVerifyAgreements) {
                        dispatch(goToNextOnboardingStep())
                      }
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Edit agreement"
                      description="The label for the edit legal agreement button in the legal agreements table"
                      id="legalAgreements.table.legalAgreement.action.edit.label"
                    />
                  </MenuItem>
                  <MenuItem
                    isDisabled={!hasPermission(whoami?.roles, "legal:delete")}
                    onClick={() => {
                      setAgreementBeingDeleted(agreement)
                      openDeleteAgreementModal()
                      if (isOnboardingVerifyAgreements) {
                        dispatch(goToNextOnboardingStep())
                      }
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Delete agreement"
                      description="The label for the delete legal agreement button in the legal agreements table"
                      id="legalAgreements.table.action.delete.label"
                    />
                  </MenuItem>
                  {!missingRenewalDefinition && agreement.tools.length > 0 && (
                    <MenuItem
                      onClick={() => {
                        setLegalAgreementBeingRenewed(agreement)
                        openStartRenewalModal()
                        if (isOnboardingVerifyAgreements) {
                          dispatch(goToNextOnboardingStep())
                        }
                      }}
                    >
                      <FormattedMessage
                        id="legalAgreements.table.action.startRenewal.label"
                        description="The label for the start renewal button in the legal agreements table"
                        defaultMessage="Start renewal"
                      />
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            )
          },
        }),
      ],
    [
      intl,
      location,
      missingRenewalDefinition,
      openDeleteAgreementModal,
      openStartRenewalModal,
      schemaColumns,
      whoami?.roles,
      dispatch,
      isOnboardingVerifyAgreements,
    ]
  )

  if (tabIndex === undefined || !updateTableParams || !legalAgreementSchema || !shownColumns || !columnsWithActions) {
    return null
  }

  if (tabResult[tabIndex]?.isLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }
  const agreements = tabResult[tabIndex]?.data
  if (!agreements) {
    return null
  }

  if (tabResult[tabIndex]?.isError) {
    throw tabResult[tabIndex]?.error
  }

  const onBulkUploadSubmit = async (uploadedDocuments: DocumentMinimal[]) => {
    // Trigger bulk-processing
    await backgroundExtractDocuments({
      documentBatchExtractionRequest: {
        extraction_request_type: "unknown_legal_agreement",
        document_ids: uploadedDocuments.map((doc) => doc.id),
      },
    }).unwrap()

    // Sort by created_at descending to show the legal agreements at the top of the table as they get extracted
    updateTableParams({ sorting: [{ id: "created_at", desc: true }] })

    toast({
      status: "success",
      description: (
        <FormattedMessage
          defaultMessage="{documentsCount, plural, one {Agreement} other {Agreements}} will extract in the background and appear in the table once extraction is complete."
          description="Toast success message for triggering background extraction of legal agreements"
          id="legalAgreements.table.bulkUpload.started.success"
          values={{ documentsCount: uploadedDocuments.length }}
        />
      ),
      duration: 60_000,
    })
    await extractingDocuments.refetch().unwrap()
  }

  const savedViewProps: SavedViewProps = isLiteOrganization
    ? {}
    : {
        savedViews: savedViewData,
        savedViewState: tableParams.savedViewState,
        onSavedViewStateChange: (savedViewState) => updateTableParams({ savedViewState }),
      }

  return (
    <>
      <Flex direction="column" flexGrow={1} minHeight={0}>
        <FullDataTableHeader
          displayTitle={intl.formatMessage({
            id: "legalAgreements.title",
            description: "title of the legal agreements page",
            defaultMessage: "Agreements",
          })}
          {...savedViewProps}
        >
          <TablePageHeader
            pinnedFilters={["agreement_type"]}
            tableId={LEGAL_AGREEMENTS_TABLE_ID}
            tabProps={{
              data: filterTabs.map((tab, index) => {
                const total = tabResult[index]?.data?.total ?? 0
                return {
                  ...tab,
                  total,
                  isDisabled: total === 0,
                }
              }),
              handleChangeTab,
              selectedIndex: tabIndex,
            }}
            filterMap={tableParams.filterMap}
            onChangeFilters={(filterMap) => updateTableParams({ filterMap })}
            selectedColumns={shownColumns}
            primarySearch={{
              column: primaryColumn,
              currentPrimaryFilter: tableParams.primaryFilter?.fields,
              placeholder: intl.formatMessage({
                id: "agreement.list.search.name",
                description: "text showing the option to search agreement by the name column",
                defaultMessage: "Search agreement by name",
              }),
              onPrimaryFilterChange: (fields) =>
                updateTableParams({ primaryFilter: fields && { column: primaryColumn, fields } }),
            }}
            objectSchema={legalAgreementSchema}
            {...savedViewProps}
            beforeSavedView={
              hasPermission(whoami?.roles, "legal:create") && (
                <>
                  {extractingDocuments.data &&
                    (extractingDocuments.data.documents.length > 0 ||
                      extractingDocuments.data.has_uncategorized_emails) && (
                      <Popover trigger="hover" isLazy>
                        <PopoverTrigger>
                          <IconButton
                            variant="ghost"
                            aria-label={intl.formatMessage({
                              defaultMessage: "Processing documents",
                              description: "ARIA label for the processing documents spinner with popover",
                              id: "legalAgreements.table.bulkUpload.processingDocuments.ariaLabel",
                            })}
                            icon={<CircularProgress size="1.3em" color="purple.500" isIndeterminate={true} />}
                          />
                        </PopoverTrigger>
                        <PopoverArrow />
                        <PopoverContent minWidth="40ch">
                          <List spacing={3} maxHeight="410px" overflowY="auto">
                            {extractingDocuments.data.has_uncategorized_emails && (
                              <ListItem
                                borderBottomWidth="1px"
                                borderColor="gray.200"
                                px={3}
                                py={2}
                                m="0 !important"
                                display="flex"
                                gap={1}
                              >
                                <Text
                                  as="span"
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                  textOverflow="ellipsis"
                                  fontSize="sm"
                                  flexShrink={1}
                                >
                                  <FormattedMessage
                                    defaultMessage="Finding agreements from Gmail"
                                    description="The text for the gmail analysis status in the bulk upload status popover on the legal agreements page"
                                    id="legalAgreements.table.bulkUpload.gmailStatus"
                                  />
                                </Text>
                                <Badge size="sm" colorScheme="purple" ml="auto">
                                  <FormattedMessage
                                    defaultMessage="Processing..."
                                    description="The badge text for files currently processing in the bulk upload status popover on the legal agreements page"
                                    id="legalAgreements.table.bulkUpload.badgeText.processing"
                                  />
                                </Badge>
                              </ListItem>
                            )}
                            {extractingDocuments.data.documents
                              .toSorted((a, b) => collator.compare(a.file_name ?? "", b.file_name ?? ""))
                              .map((document) => (
                                <ListItem
                                  key={document.id}
                                  borderBottomWidth="1px"
                                  borderColor="gray.200"
                                  px={3}
                                  py={2}
                                  m="0 !important"
                                  display="flex"
                                  gap={1}
                                >
                                  <Text
                                    as="span"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    textOverflow="ellipsis"
                                    fontSize="sm"
                                    flexShrink={1}
                                    title={document.file_name ?? undefined}
                                  >
                                    {document.file_name}
                                  </Text>

                                  <Badge
                                    size="sm"
                                    colorScheme={
                                      document.status === "quarantined" || document.extraction_status === "failed"
                                        ? "red"
                                        : document.extraction_status === "completed"
                                          ? "success"
                                          : "purple"
                                    }
                                    ml="auto"
                                  >
                                    {documentStatusText(document, intl)}
                                  </Badge>
                                </ListItem>
                              ))}
                          </List>
                        </PopoverContent>
                      </Popover>
                    )}
                  {agreements.items.length > 0 && (
                    <IconButtonWithTooltip
                      variant="outline"
                      label={intl.formatMessage({
                        defaultMessage: "Upload Documents",
                        description: "Button label for bulk-uploading documents",
                        id: "legalAgreements.table.bulkUpload.button",
                      })}
                      icon={<Icon as={UploadIcon} />}
                      onClick={bulkUploadModal.onOpen}
                    />
                  )}
                </>
              )
            }
            afterSavedView={
              <SchemaTableColumnCustomization
                activeColumns={shownColumns}
                primaryColumn={primaryColumn}
                onActiveColumnsChange={(selectedColumns) => updateTableParams({ selectedColumns })}
                objectSchema={legalAgreementSchema}
              />
            }
          />
        </FullDataTableHeader>
        {agreements.items.length === 0 && tableParams.filterMap.size === 0 && !tableParams.primaryFilter ? (
          <Center px={8} height="full">
            <Box textAlign="center" maxWidth="350px">
              <Text fontWeight="semibold" fontSize="lg" paddingY={2}>
                {tabIndex === pendingReviewTabIndex ? (
                  <>
                    <img src={emptyStateGif} />
                    <FormattedMessage
                      id="legalAgreements.table.pendingReview.emptyState"
                      description="Text to display instead of the legal agreements table when there are no pending review legal agreements for the tool and the user has no permission to create legal agreements"
                      defaultMessage="No agreements are pending review"
                    />
                  </>
                ) : (
                  <FormattedMessage
                    id="legalAgreements.table.pendingReview.emptyState"
                    description="Text to display instead of the legal agreements table when there are no pending review legal agreements for the tool and the user has no permission to create legal agreements"
                    defaultMessage="No agreements added."
                  />
                )}
              </Text>
              {hasPermission(whoami?.roles, "legal:create") && (
                <>
                  <Text color="gray.600" fontSize="md">
                    <FormattedMessage
                      id="legalAgreements.table.pendingReview.emptyState.description"
                      description="Description of empty state for pending review legal agreements on the legal agreements page"
                      defaultMessage="Upload your contracts, including Order Forms, MNDAs, MSAs, and others into BRM"
                    />
                  </Text>
                  <Button gap={2} variant="solid" colorScheme="brand" margin={4} onClick={bulkUploadModal.onOpen}>
                    <Icon as={UploadIcon} />
                    <FormattedMessage
                      id="legalAgreements.table.pendingReview.emptyState.upload"
                      description="Upload documents button label for agreements empty state"
                      defaultMessage="Upload Documents"
                    />
                  </Button>
                </>
              )}
            </Box>
          </Center>
        ) : (
          <DataTable<LegalAgreementListItem>
            data={agreements.items}
            columns={columnsWithActions}
            columnPinning={columnPinning}
            sorting={tableParams.sorting}
            onSortingChange={(sorting) =>
              updateTableParams(sorting instanceof Function ? { sorting: sorting(tableParams.sorting) } : { sorting })
            }
            paginationProps={{
              page: tableParams.page,
              pageSize: tableParams.pageSize,
              onPageChange: (page) => updateTableParams({ page }),
              onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
              totalListElements: agreements.total,
            }}
            isSticky
            onRowClick={(row) => {
              // Only show review queue sidebar if user is opening agreement from the pending review tab
              if (isOnboardingVerifyAgreements) {
                dispatch(goToNextOnboardingStep())
              }
              return navigate(pathForLegalAgreement(row.id, tabIndex === 0), { state: { prevLocation: location } })
            }}
          />
        )}
      </Flex>
      {bulkUploadModal.isOpen && (
        // set returnFocusOnClose false to prevent unintended side nav focus issues
        <DocumentsBulkUploadModal
          {...bulkUploadModal}
          onSubmit={onBulkUploadSubmit}
          returnFocusOnClose={false}
          objectType="LegalAgreement"
          renderDuplicateFilesMessage={(existingAgreements) =>
            intl.formatMessage(
              {
                defaultMessage:
                  "This document will not create a new agreement because it is part of {agreementsCount, plural, one {an existing agreement} other {existing agreements}}:",
                description: "Tooltip for already uploaded files",
                id: "documents.modal.upload.alreadyUploaded.tooltip",
              },
              { agreementsCount: existingAgreements.length }
            )
          }
        />
      )}
      {agreementBeingDeleted && (
        <DeleteLegalAgreementModal {...deleteAgreementModal} legalAgreementBeingDeleted={agreementBeingDeleted} />
      )}
      {legalAgreementBeingRenewed && startRenewalModal.isOpen && (
        <StartWorkflowModal initialLegalAgreementId={legalAgreementBeingRenewed.id} {...startRenewalModal} />
      )}
    </>
  )
}
export default LegalAgreementList
