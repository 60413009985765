import { Box, Button, Container, Flex, Heading, Icon, Stack, Text } from "@chakra-ui/react"
import posthog from "posthog-js"
import { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useGetIntegrationV1PrivateQuery, useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import BrmLogo from "../../components/BrmLogo.js"
import { CalendarCheckIcon, LockWithKeyHoleIcon, NextIcon, ThreeStarsIcon } from "../../components/icons/icons.js"
import { goToNextOnboardingStep, initializeOnboardingState, selectIsOnboardingInitialized } from "./onboarding-slice.js"

function Header() {
  return (
    <>
      <BrmLogo h="200px" w="200px" mb="-1rem" userSelect="none" />
      <Heading as="h1" size="xs" userSelect="none" textAlign="center">
        <FormattedMessage
          id="onboarding.connect-gmail.heading"
          description="Onboarding connect Gmail heading"
          defaultMessage="All your Contracts in One Place!"
        />
        <Text fontSize="lg" fontWeight="normal">
          <FormattedMessage
            id="onboarding.connect-gmail.subtitle"
            description="Onboarding connect Gmail subtitle"
            defaultMessage="Connect your Gmail and let BRM pull every contract into a single organized system"
          />
        </Text>
      </Heading>
    </>
  )
}

interface FeatureCardProps {
  icon: React.ElementType
  heading: React.ReactNode
  subtitle: React.ReactNode
}

function FeatureCard({ icon, heading, subtitle }: FeatureCardProps) {
  return (
    <Box>
      <Flex gap="1rem">
        <Icon as={icon} borderRadius="md" p=".75rem" boxSize="3rem" bg="brand.lime" mt=".25rem" />
        <Stack>
          <Text fontSize="lg" fontWeight="bold">
            {heading}
          </Text>
          <Text fontSize="md" fontWeight="normal" lineHeight="1.75">
            {subtitle}
          </Text>
        </Stack>
      </Flex>
    </Box>
  )
}

function FeatureCards() {
  return (
    <Stack py="1rem" spacing="1rem" alignItems="center">
      <FeatureCard
        icon={ThreeStarsIcon}
        heading={
          <FormattedMessage
            id="onboarding.connect-gmail.automagically-callout.heading"
            description="Onboarding connect Gmail automagically callout heading"
            defaultMessage="Instant Organization"
          />
        }
        subtitle={
          <FormattedMessage
            id="onboarding.connect-gmail.automagically-callout.subtitle"
            description="Onboarding connect Gmail automagically callout subtitle"
            defaultMessage="Get to your important documents in seconds. No manual digging required."
          />
        }
      />
      <FeatureCard
        icon={CalendarCheckIcon}
        heading={
          <FormattedMessage
            id="onboarding.connect-gmail.never-miss-renewal-callout.heading"
            description="Onboarding connect Gmail never miss renewal callout heading"
            defaultMessage="Never Miss a Renewal"
          />
        }
        subtitle={
          <FormattedMessage
            id="onboarding.connect-gmail.never-miss-renewal-callout.subtitle"
            description="Onboarding connect Gmail never miss renewal callout subtitle"
            defaultMessage="Automated reminders keep crucial contract dates front and center."
          />
        }
      />
      <FeatureCard
        icon={LockWithKeyHoleIcon}
        heading={
          <FormattedMessage
            id="onboarding.connect-gmail.completely-secure-callout.heading"
            description="Onboarding connect Gmail completely secure callout heading"
            defaultMessage="Safe & Secure"
          />
        }
        subtitle={
          <FormattedMessage
            id="onboarding.connect-gmail.completely-secure-callout.subtitle"
            description="Onboarding connect Gmail completely secure callout subtitle"
            defaultMessage="Read-only access to your inbox so your data stays yours. Privacy is top priority."
          />
        }
      />
    </Stack>
  )
}

function ConnectButton() {
  return (
    <Button
      id="connect-gmail-button"
      colorScheme="brand"
      fontSize="lg"
      fontWeight="semibold"
      onClick={() => {
        posthog.capture("onboarding_connect_gmail_button_clicked", {
          step: "contract_collector.connect_gmail",
        })
        window.location.href = `${import.meta.env.VITE_API_BASE_URL}/oauth/v1/connect/gmail_oauth`
      }}
    >
      <Flex alignItems="center" gap="0.5rem">
        <FormattedMessage
          id="onboarding.connect-gmail.button"
          description="Onboarding connect Gmail button"
          defaultMessage="Connect Gmail to Start"
        />
        <Icon as={NextIcon} boxSize="1rem" />
      </Flex>
    </Button>
  )
}

export default function OnboardingConnectGmail() {
  const isInitialized = useSelector(selectIsOnboardingInitialized)
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: integrations } = useGetIntegrationV1PrivateQuery()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (whoami?.organization.is_lite === false) {
      navigate("/")
      return
    }

    if (whoami?.organization.is_lite === true) {
      if (!isInitialized) {
        dispatch(initializeOnboardingState({ step: "contract_collector.connect_gmail", userHasOnboardingFlow: true }))
      } else if (integrations?.some((i) => i.provider === "gmail_oauth")) {
        dispatch(goToNextOnboardingStep())
      }
    }
  }, [integrations, dispatch, isInitialized, whoami, navigate])
  return (
    <Container maxW="lg" py={{ base: "12", md: "24" }} px={{ base: "0", sm: "8" }}>
      <Box py={{ base: "0", sm: "8" }} px={{ base: "4", sm: "10" }}>
        <Stack spacing="1rem" alignItems="center">
          <Header />
          <FeatureCards />
          <ConnectButton />
        </Stack>
      </Box>
    </Container>
  )
}
